import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
import { useSelector } from 'react-redux';
import planCard1 from '../../assets/webp-images/plan-header-card-1.png';
import planCard2 from '../../assets/webp-images/plan-header-card-2.png';
import planCard3 from '../../assets/webp-images/plan-header-card-3.png';
import planCard4 from '../../assets/webp-images/plan-header-card-4.png';
import appleIcon from '../../assets/new-icons/apple-icon.png';
import playstoreIcon from '../../assets/new-icons/playstore-icon.png';
import instaIcon from '../../assets/new-icons/insta-icon.png';
import starIcon from '../../assets/star.svg';
import planTestimonial1 from '../../assets/webp-images/plan-testimonial-1.png';
import planTestimonial2 from '../../assets/webp-images/plan-testimonial-2.png';
import planTestimonial3 from '../../assets/webp-images/plan-testimonial-3.png';
import planTestimonial4 from '../../assets/webp-images/plan-testimonial-4.png';
import planTestimonial5 from '../../assets/webp-images/plan-testimonial-5.png';
import planCourse1 from '../../assets/webp-images/plan-course-1.png';
import planCourse2 from '../../assets/webp-images/plan-course-2.png';
import planCourse3 from '../../assets/webp-images/plan-course-3.png';
import planCourse4 from '../../assets/webp-images/plan-course-4.png';
import planDesigned1 from '../../assets/webp-images/plan-designed-1.png';
import planDesigned2 from '../../assets/webp-images/plan-designed-2.png';
import planDesigned3 from '../../assets/webp-images/plan-designed-3.png';
import planDesigned4 from '../../assets/webp-images/plan-designed-4.png';
import planDesigned5 from '../../assets/webp-images/plan-designed-5.png';
import planInstructor1 from '../../assets/webp-images/plan-instructor-1.png';
import planInstructor2 from '../../assets/webp-images/plan-instructor-2.png';
import planInstructor3 from '../../assets/webp-images/plan-instructor-3.png';
import planInstructor4 from '../../assets/webp-images/plan-instructor-4.png';
import planInstructor5 from '../../assets/webp-images/plan-instructor-5.png';
import planInstructor6 from '../../assets/webp-images/plan-instructor-6.png';
import planInstructor7 from '../../assets/webp-images/plan-instructor-7.png';
import planInstructor8 from '../../assets/webp-images/plan-instructor-8.png';
import planFounders1 from '../../assets/webp-images/plan-founders-1.png';
import planFounders2 from '../../assets/webp-images/plan-founders-2.png';
import challengeServices1 from '../../assets/videos/challenge-services-1.mp4';
import challengeServices2 from '../../assets/videos/challenge-services-2.mp4';
import classServices1 from '../../assets/videos/services-class-1.mp4';
import classServices2 from '../../assets/videos/services-class-2.mp4';
import classServices3 from '../../assets/videos/services-class-3.mp4';
import courseServices1 from '../../assets/videos/services-course-1.mp4';
import courseServices2 from '../../assets/videos/services-course-2.mp4';
import courseServices3 from '../../assets/videos/services-course-3.mp4';
import planHappyUsers from '../../assets/webp-images/plan-happy-users.png';
import CloseRecordingModelIcon from '../../assets/new-icons/close-popup.svg';

import volumeMutedIcon from '../../assets/new-icons/volume-muted-icon.png';
import volumeIcon from '../../assets/new-icons/volume-icon.png';
import liveIcon from '../../assets/new-icons/live-icon.png';
import planVideo from '../../assets/videos/plan-testimonial.mp4';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import PlanScreenAboutItem from './components/PlanScreenAboutItem';
import PlanScreenDesignedItem from './components/PlanScreenDesignedItem';
import PrimaryButton from '../../components-v2/buttons/PrimaryButton';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Mixpanel } from '../../analytics/Mixpanel';

import 'swiper/css';
import './PlanScreen.css';
import {
	DAILY_CLASS_PAYMENT_TYPES,
	Faqs,
	generateUniqueId,
	getRazorpayOptions,
	isEmpty,
	loadRazorpayScript,
} from '../../utils/utils';
import FAQ from './components/FAQ';
import NumberTicker from '../../components-v2/number-ticker/NumberTicker';
import {
	enrolUserToLiveClasses,
	getLiveStreamingPlans,
	signUpUser,
} from '../../services/Firebase';
import Shimmer from '../../components-v2/shimmer/Shimmer';
import PlanVariant from './components/PlanVariant';
import moment from 'moment-timezone';
import BottomSheet from '../../components/model/BottomSheet';
import PhoneInput from '../../components/phoneInput/PhoneInput';
import TextInput from '../../components/textInput/TextInput';
import { addUsers, setReloadPlan } from '../../store/features/AuthSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import PaymentFailedModal from '../../components-v2/models/PaymentFailedModel';

interface TriggeredEvents {
	[key: string]: boolean;
}

const sectionRanges = {
	section1: {
		start: 0,
		end: 500,
		section_heading: 'Get everything under one membership',
	},
	section2: {
		start: 100,
		end: 900,
		section_heading: 'Real stories, real impact',
	},
	section3: {
		start: 500,
		end: 1200,
		section_heading: 'One platform for mental wellness',
	},
	section4: {
		start: 1000,
		end: 1700,
		section_heading: 'Designed for people like you',
	},
	section5: { start: 1500, end: 2000, section_heading: 'App store reviews' },
	section6: {
		start: 1800,
		end: 2100,
		section_heading: 'Top 1% seniormost instructors for you',
	},
	section7: { start: 2000, end: 2300, section_heading: 'Meet our founders' },
	section8: { start: 2300, end: 2700, section_heading: 'FAQs' },
} as { [key: string]: { start: number; end: number; section_heading: string } };

const planAppstoreReviews = [
	{
		rating: 5,
		nameDate: 'Anu Gautam, 02/03/2025',
		title: 'Life-changing app',
		desc: 'I love how flexible the time slots are—it’s so easy to fit meditation into my day. The variety of sessions is amazing!',
	},
	{
		rating: 5,
		nameDate: 'Niyati Verma, 11/02/2025',
		title: 'Best meditation tool',
		desc: 'It is so easy to use! It helped me start meditation and stay consistent. I even overcame my anxiety with Anxiety Release course',
	},
	{
		rating: 5,
		nameDate: 'Parag Sahu, 14/08/2024',
		title: 'Improved my sleep',
		desc: 'After just a few regular sessions, I noticed my sleep has improved so much. Can’t thank this app enough!',
	},
	{
		rating: 5,
		nameDate: 'Karan Mehta, 20/01/2025',
		title: 'A journey to self-discovery',
		desc: 'Each session feels like a new adventure. I’ve learned so much about myself, and it’s helped me grow in ways I never expected.',
	},
	{
		rating: 5,
		nameDate: 'Sonia Patel, 15/09/2024',
		title: 'Stress relief at its best',
		desc: 'This app has been instrumental in managing my stress. The guided meditations are calming and truly effective!',
	},
	{
		rating: 5,
		nameDate: 'Ravi Kumar, 04/02/2024',
		title: 'Enhancing focus and clarity',
		desc: 'I struggled with concentration for years. The focus sessions are a game changer. I feel sharper and more productive!',
	},
];

function PlanScreen({ onetime }: { onetime: boolean }) {
	const [muted, setMuted] = useState(true);
	const [plans, setPlans] = useState<any>();
	const [showLoginPopup, setShowLoginPopup] = useState(false);
	const [paymentFailedPopup, setPaymentFailedPopup] = useState(false);
	const [signupLoading, setSignupLoading] = useState(false);
	const [isPayNowClicked, setIsPayNowClicked] = useState(false);
	const [selectedPlanVariant, setSelectedPlanVariant] = useState<any>();
	const [selectedPlanVariantIndex, setSelectedPlanVariantIndex] =
		useState<any>();
	const [loader, setLoader] = useState<boolean>(true);
	const [phone, setPhone] = useState('');
	const [userName, setUserName] = useState('');
	const [selectedFAQIndex, setSelectedFAQIndex] = useState<number | null>(null);

	const userStored = useAuthenticatedUsers(true);
	const userRedux = useSelector((state: any) => state?.auth?.user?.data);
	const [triggeredEvents, setTriggeredEvents] = useState<TriggeredEvents>({
		section1: false,
		section2: false,
		section3: false,
		section4: false,
		section5: false,
		section6: false,
		section7: false,
		section8: false,
	});

	const videoRef = useRef<HTMLVideoElement>(null);

	const userData = useMemo(() => {
		return userStored || userRedux;
	}, [userStored, userRedux]);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onFAQClickHandler = (index: number) => {
		try {
			if (selectedFAQIndex !== index) {
				Mixpanel.track('click_view_FAQs_on_plan_landing_page', {
					event_action: 'clicked',
					faq_heading: Faqs[index],
					event_page: 'plan_landing_page',
				});
			}
		} catch (e) {}
		setSelectedFAQIndex((prevState) => (prevState === index ? null : index));
	};

	const handleRetryPayment = () => {
		Mixpanel.track('click_retry_payment', {
			event_action: 'clicked',
			plan_name: onetime
				? plans[selectedPlanVariantIndex]?.display_name
				: plans[selectedPlanVariantIndex]?.app_display_name,
			plan_amount: plans[selectedPlanVariantIndex]?.total_price,
			event_page: 'plan_landing_page',
		});
		setPaymentFailedPopup(false);
		handleProceedToPay();
	};

	const handlePlanVariantClick = (
		selectedIndex: number,
		duration: number,
		price: number
	) => {
		setSelectedPlanVariantIndex(selectedIndex);
		setSelectedPlanVariant(plans[selectedIndex]);

		Mixpanel.track('click_to_choose_plan_duration', {
			event_action: 'clicked',
			duration: duration,
			plan_price: price,
		});
	};

	const handleRazorpay = async (user: any) => {
		const res = await loadRazorpayScript(
			'https://checkout.razorpay.com/v1/checkout.js'
		);

		if (!res) {
			toast.error(`Razorpay SDK failed to load. Are you online?`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
				hideProgressBar: true,
			});
			return;
		}

		const paymentType = onetime
			? DAILY_CLASS_PAYMENT_TYPES.ONE_TIME
			: DAILY_CLASS_PAYMENT_TYPES.SUBSCRIPTION;

		const planDocId = generateUniqueId(
			(user?.uid || userData?.uid)!,
			selectedPlanVariant.product_id
		);

		const response = await enrolUserToLiveClasses(
			user.uid || userData.uid,
			selectedPlanVariant,
			planDocId,
			paymentType
		);

		const options = getRazorpayOptions(
			onetime ? 'Daily Live Class' : 'Seek daily subscription',
			selectedPlanVariant?.total_price,
			onetime ? 'Daily Live Class' : 'Seek daily subscription',
			user.phone || '9959637700',
			user.name || '',
			selectedPlanVariant?.currency,
			response?.order_id,
			paymentType,
			async function (response: any) {
				if (response.razorpay_payment_id) {
					Mixpanel.track('view_payment_successful', {
						event_action: 'viewed',
						plan_amount: selectedPlanVariant.total_price,
						plan_name: onetime
							? selectedPlanVariant.display_name
							: selectedPlanVariant.app_display_name,
						phone:
							user?.phoneNumber ||
							user?.phone ||
							userData?.phoneNumber ||
							userData?.phone,
						userId: user?.uid || userData?.uid,
						event_page: 'payment_screen/payment_one',
					});
					navigate('/plan/payment-successful', {
						state: {
							selectedPlanVariant: selectedPlanVariant,
							course: null,
							currently_active_variant:
								user?.activeStreamingPlan?.currently_active_variant,
						},
						replace: false,
					});
					dispatch(setReloadPlan(true));
				} else {
					setPaymentFailedPopup(true);
					Mixpanel.track('payment_failure', {
						event_action: 'viewed',
						plan_amount: selectedPlanVariant.total_price,
						plan_name: onetime
							? selectedPlanVariant?.display_name
							: selectedPlanVariant?.app_display_name,
						phone:
							user?.phoneNumber ||
							user?.phone ||
							userData?.phoneNumber ||
							userData?.phone,
						event_page: 'payment_screen/payment_one',
					});
				}
			},
			{
				ondismiss: function () {
					setPaymentFailedPopup(true);
					Mixpanel.track('payment_failure', {
						event_action: 'viewed',
						plan_amount: selectedPlanVariant.total_price,
						plan_name: onetime
							? selectedPlanVariant.display_name
							: selectedPlanVariant.app_display_name,
						phone:
							user?.phoneNumber ||
							user?.phone ||
							userData?.phoneNumber ||
							userData?.phone,
						event_page: 'payment_screen/payment_one',
					});
				},
			}
		);

		const paymentObject = (window as any).Razorpay(options);
		paymentObject.open();
	};

	const handleProceedToPay = (cta?: boolean) => {
		if (cta) {
			Mixpanel.track('click_cta_pay_now', {
				event_action: 'clicked',
				plan_name: onetime
					? selectedPlanVariant?.display_name
					: selectedPlanVariant?.app_display_name,
				plan_amount: selectedPlanVariant?.total_price,
				event_page: 'plan_landing_page',
			});
		} else {
			Mixpanel.track('click_proceed_to_pay', {
				event_action: 'clicked',
				plan_name: onetime
					? selectedPlanVariant?.display_name
					: selectedPlanVariant?.app_display_name,
				plan_amount: selectedPlanVariant?.total_price,
				event_page: 'plan_landing_page',
			});
		}

		if (isEmpty(userData)) {
			setShowLoginPopup(true);
			Mixpanel.track('view_details_popup_plan_page', {
				event_action: 'viewed',
				plan_amount: selectedPlanVariant?.total_price,
				plan_name: onetime
					? selectedPlanVariant?.display_name
					: selectedPlanVariant?.app_display_name,
				startDate: moment().format('DD MMM YYYY'),
				event_page: 'plan_landing_page',
			});
			return;
		}

		handleRazorpay(userData);

		setIsPayNowClicked(true);
		setTimeout(() => {
			setIsPayNowClicked(false);
		}, 10000);
	};

	const handlePhoneChange = (value: string) => {
		setPhone(value);
	};

	const handleChangeUserName = (event: any) => {
		setUserName(event.target.value);
	};

	const handleSignup = () => {
		setSignupLoading(true);
		Mixpanel.track('click_signup_popup_plan_page', {
			event_action: 'viewed',
			name: userName,
			phone: phone,
			plan_amount: selectedPlanVariant?.total_price,
			plan_name: onetime
				? selectedPlanVariant?.display_name
				: selectedPlanVariant?.app_display_name,
			startDate: moment().format('DD MMM YYYY'),
			event_page: 'plan_landing_page',
		});
		signUpUser({ phone, name: userName, source: 'WEB' }, 'web').then(
			(response) => {
				setShowLoginPopup(false);
				if (response.status === 200 || response.status === 201) {
					dispatch(addUsers(response.userData));
					localStorage.setItem('user', JSON.stringify(response.userData));
					handleRazorpay(response.userData);
					setShowLoginPopup(false);
					setSignupLoading(false);
					setIsPayNowClicked(true);
					setTimeout(() => {
						setIsPayNowClicked(false);
					}, 10000);
					Mixpanel.identify(response.userData.uid);
					Mixpanel.people.set({
						$id: response.userData.uid,
						$name: response.userData.name,
						$phone: response.userData?.phone,
					});
				}
			}
		);
	};

	const markMixPanelEvent = (position: number) => {
		const checkAndTriggerEvent = (
			sectionKey: string,
			start: number,
			end: number
		) => {
			if (
				!triggeredEvents[sectionKey] &&
				position >= start - 10 &&
				position <= end + 10
			) {
				Mixpanel.track('view_payment_landing_page_section', {
					event_action: 'viewed',
					event_section: sectionKey,
					section_heading: sectionRanges[sectionKey].section_heading,
					event_page: 'plan_landing_page',
					plan_name: onetime
						? `${plans?.[0]?.display_name}, ${plans?.[1]?.display_name}`
						: `${plans?.[0]?.app_display_name}, ${plans?.[1]?.app_display_name}`,
				});
				setTriggeredEvents((prevEvents: any) => ({
					...prevEvents,
					[sectionKey]: true,
				}));
			}
		};

		Object.entries(sectionRanges).forEach(([sectionKey, { start, end }]) => {
			checkAndTriggerEvent(sectionKey, start, end);
		});
	};

	useEffect(() => {
		if (onetime) {
			Faqs.splice(9, 2);
		}
		window.addEventListener('scroll', () => {
			if (
				videoRef.current &&
				videoRef.current?.getBoundingClientRect().top < window.innerHeight
			) {
				videoRef.current.play();
			}
		});
	}, []);

	useEffect(() => {
		getLiveStreamingPlans(onetime).then((plansResponse) => {
			const sortedArray = plansResponse.plans[0]?.variants?.sort(
				(a: any, b: any) => b?.duration - a?.duration
			);

			setPlans(sortedArray);
			setSelectedPlanVariant(sortedArray[0]);
			setLoader(false);
		});
	}, []);

	useEffect(() => {
		Mixpanel.startRecording();

		return () => Mixpanel.stopRecording();
	}, []);

	useEffect(() => {
		if (!triggeredEvents['section1'] && plans) {
			Mixpanel.track('view_payment_landing_page_section', {
				event_action: 'viewed',
				event_section: 'section1',
				section_heading: 'Choose your perfect plan!',
				event_page: 'plan_landing_page',
				plan_name: `${plans?.[0]?.app_display_name}, ${plans?.[1]?.app_display_name}`,
			});
			setTriggeredEvents((prevEvents: any) => ({
				...prevEvents,
				section1: true,
			}));
		}
		const handleScroll = () => {
			markMixPanelEvent(window.scrollY);
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [triggeredEvents, plans]);

	useEffect(() => {
		if (plans && plans.length) {
			Mixpanel.track('view_payment_landing_page', {
				event_action: 'viewed',
				event_page: 'plan_landing_page',
				plan_name: onetime
					? `${plans?.[0]?.display_name}, ${plans?.[1]?.display_name}`
					: `${plans?.[0]?.app_display_name}, ${plans?.[1]?.app_display_name}`,
			});
		}
	}, [plans]);

	if (loader) {
		return (
			<div style={{ width: '100%', maxWidth: '420px', margin: 'auto' }}>
				<Shimmer
					style={{
						height: '100px',
						width: '100%',
					}}
				/>
				<Shimmer style={{ height: '200px', width: '100%' }} />
				<Shimmer style={{ height: '300px', width: '100%' }} />
				<Shimmer style={{ height: '200px', width: '100%' }} />
			</div>
		);
	}

	return (
		<>
			<div className='plan-screen'>
				<div className='plan-screen-header'>
					<div className='plan-screen-header-bg'></div>
					<p className='plan-screen-title'>
						Get everything under
						<br />
						<span>one membership</span>
					</p>
					<div className='plan-screen-header-cards'>
						<img src={planCard1} alt='' />
						<img src={planCard2} alt='' />
						<img src={planCard3} alt='' />
						<img src={planCard4} alt='' />
					</div>
					<div className='plan-screen-header-numbers'>
						<div className='plan-screen-header-numbers-item'>
							<p className='plan-screen-header-numbers-item-number'>
								<NumberTicker value={10} />
								K+
							</p>
							<p className='plan-screen-header-numbers-item-text'>Members</p>
						</div>
						<div className='plan-screen-header-numbers-item'>
							<p
								style={{ color: '#FAA53D' }}
								className='plan-screen-header-numbers-item-number'>
								<NumberTicker value={20} />+
							</p>
							<p className='plan-screen-header-numbers-item-text'>
								Meditation styles
							</p>
						</div>
						<div className='plan-screen-header-numbers-item'>
							<p className='plan-screen-header-numbers-item-number'>
								<NumberTicker value={10} />+
							</p>
							<p className='plan-screen-header-numbers-item-text'>Countries</p>
						</div>
					</div>
					<div className='plan-screen-header-numbers'>
						<div className='plan-screen-header-numbers-item'>
							<div className='plan-screen-header-numbers-item-icons'>
								<img src={appleIcon} alt='' />
								<img src={playstoreIcon} alt='' />
							</div>
							<p
								style={{ color: '#FAA53D' }}
								className='plan-screen-header-numbers-item-number'>
								<NumberTicker value={4.8} decimalPlaces={1} />{' '}
								<img src={starIcon} alt='' />
							</p>
							<p className='plan-screen-header-numbers-item-text'>
								App/Play store rating
							</p>
						</div>
						<div className='plan-screen-header-numbers-item'>
							<div className='plan-screen-header-numbers-item-icons'>
								<img src={instaIcon} alt='' />
							</div>
							<p className='plan-screen-header-numbers-item-number'>
								<NumberTicker value={15} />
								K+
							</p>
							<p className='plan-screen-header-numbers-item-text'>
								Instagram followers
							</p>
						</div>
					</div>
					<div className='plan-screen-header-testimonials'>
						<p className='plan-screen-title'>
							Real stories,
							<span> real impact</span>
						</p>
						<Swiper
							spaceBetween={10}
							slidesPerView={1.2}
							loop={true}
							speed={600}
							autoplay={{
								delay: 3000,
								disableOnInteraction: true,
							}}
							modules={[Autoplay]}
							className='plan-screen-header-testimonials-swiper'>
							<SwiperSlide className='plan-screen-header-testimonials-swiper-slide'>
								<img src={planTestimonial1} alt='' />
							</SwiperSlide>
							<SwiperSlide className='plan-screen-header-testimonials-swiper-slide'>
								<img src={planTestimonial2} alt='' />
							</SwiperSlide>
							<SwiperSlide className='plan-screen-header-testimonials-swiper-slide'>
								<img src={planTestimonial3} alt='' />
							</SwiperSlide>
							<SwiperSlide className='plan-screen-header-testimonials-swiper-slide'>
								<img src={planTestimonial4} alt='' />
							</SwiperSlide>
							<SwiperSlide className='plan-screen-header-testimonials-swiper-slide'>
								<img src={planTestimonial5} alt='' />
							</SwiperSlide>
						</Swiper>
					</div>
					<div className='plan-screen-header-stats'>
						<div className='plan-screen-header-stats-item'>
							<p className='plan-screen-header-stats-item-number'>80%</p>
							<p className='plan-screen-header-stats-item-text'>
								experienced uninterrupted <span>deep sleep</span>
							</p>
						</div>
						<div className='plan-screen-header-stats-item'>
							<p className='plan-screen-header-stats-item-number'>90%</p>
							<p className='plan-screen-header-stats-item-text'>
								experienced decrease in <span>anxiety level</span>
							</p>
						</div>
						<div className='plan-screen-header-stats-item'>
							<p className='plan-screen-header-stats-item-number'>85%</p>
							<p className='plan-screen-header-stats-item-text'>
								experienced reduced <span>chronic pain</span>
							</p>
						</div>
					</div>
				</div>
				<div className='plan-screen-about'>
					<p className='plan-screen-title'>
						One platform for
						<br />
						<span>mental wellness</span>
					</p>
					<PlanScreenAboutItem
						title='21 day Silence Challenge'
						videos={[
							challengeServices2,
							challengeServices2,
							challengeServices2,
						]}
						list={[
							'Start with 1 minute of meditation on Day 1',
							'Learn basics to build the foundation',
							'Reach 21 minutes of meditation on Day 21',
						]}
					/>
					<PlanScreenAboutItem
						title='Seek Daily Classes'
						videos={[classServices1, classServices2, challengeServices1]}
						list={[
							'Designed for consistency',
							'30 minute classes with 10 time slots',
							'New themes of meditation everyday',
						]}
					/>
					<PlanScreenAboutItem
						title='Seek Special Courses'
						videos={[courseServices1, courseServices2, courseServices3]}
						list={[
							'Courses for sleep, anxiety, gut, anger and more',
							'Lifelong tools backed by science',
							'Learn and share with a supportive cohort',
						]}
					/>
				</div>
				<div className='plan-screen-courses'>
					<p className='plan-screen-about-item-title'>Most popular courses</p>
					<Swiper
						spaceBetween={10}
						slidesPerView={1.5}
						centeredSlides
						loop={true}
						speed={3000}
						modules={[Autoplay]}
						autoplay={{
							delay: 0,
							disableOnInteraction: false,
						}}
						className='plan-screen-courses-swiper'>
						<SwiperSlide className='plan-screen-courses-swiper-slide'>
							<img src={planCourse1} alt='' />
						</SwiperSlide>
						<SwiperSlide className='plan-screen-courses-swiper-slide'>
							<img src={planCourse2} alt='' />
						</SwiperSlide>
						<SwiperSlide className='plan-screen-courses-swiper-slide'>
							<img src={planCourse3} alt='' />
						</SwiperSlide>
						<SwiperSlide className='plan-screen-courses-swiper-slide'>
							<img src={planCourse4} alt='' />
						</SwiperSlide>
					</Swiper>
				</div>
				<div className='plan-screen-designed'>
					<p style={{ margin: '60px 0 48px' }} className='plan-screen-title'>
						Designed for people <br />
						like
						<span> you</span>
					</p>
					<PlanScreenDesignedItem
						image={planDesigned1}
						title='Working Professionals'
						desc='Learn powerful techniques to switch off from work stress, stop overthinking, and truly enjoy your personal time without feeling guilty.'
					/>
					<PlanScreenDesignedItem
						image={planDesigned2}
						title='Busy Parents'
						desc='Discover simple yet effective practices to help you create balance, prioritize self-care, and be fully present for your loved ones.'
					/>
					<PlanScreenDesignedItem
						image={planDesigned3}
						title='Chronic health sufferer'
						desc='Explore tailored practices designed to ease discomfort, support your healing process through scientifically-backed meditative practices.'
					/>
					<PlanScreenDesignedItem
						image={planDesigned4}
						title='Spiritual Seekers'
						desc='Engage in guided practices that help you cultivate mindfulness, reach inner peace, and unlock a deeper sense of connection to your true self.'
					/>
					<PlanScreenDesignedItem
						image={planDesigned5}
						title='Fitness Enthusiasts'
						desc='Learn techniques to speed up recovery, boost strength, and stay consistent on your fitness journey by combining meditation with your fitness routine.'
					/>
				</div>
				<div className='plan-screen-cta'>
					<div className='plan-screen-cta-title'>
						<img src={liveIcon} alt='' />
						<p>11.2k+ meditating now</p>
					</div>
					<PrimaryButton
						onClick={() => handleProceedToPay(true)}
						shimmer
						className='plan-screen-cta-button'>
						<p>
							{onetime ? 'Get 6 months plan now' : 'Get quarterly plan now'}
						</p>
					</PrimaryButton>
				</div>
				<div className='plan-screen-reviews'>
					<p className='plan-screen-title'>
						App store
						<span> reviews</span>
					</p>
					<Swiper
						spaceBetween={10}
						slidesPerView={1}
						loop={true}
						speed={600}
						autoplay={{
							delay: 3000,
							disableOnInteraction: false,
						}}
						modules={[Autoplay]}
						className='plan-screen-reviews-swiper'>
						{planAppstoreReviews.map((review, index) => {
							return (
								<SwiperSlide
									key={index}
									className='plan-screen-reviews-swiper-slide'>
									<div className='plan-screen-reviews-item'>
										<div className='plan-screen-reviews-item-stars'>
											{Array.from(Array(review.rating).keys()).map(
												(_, index) => {
													return <img key={index} src={starIcon} alt='' />;
												}
											)}
										</div>
										<p className='plan-screen-reviews-item-name'>
											{review.nameDate}
										</p>
										<p className='plan-screen-reviews-item-title'>
											{review.title}
										</p>
										<p className='plan-screen-reviews-item-desc'>
											{review.desc}
										</p>
									</div>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</div>
				<div className='plan-screen-video'>
					<video
						ref={videoRef}
						style={{ width: '100%', borderRadius: '12px' }}
						playsInline
						loop
						muted={muted}
						src={planVideo}></video>
					<div
						onClick={() => {
							Mixpanel.track('click_mute_unmute_testimonial_video', {
								event_action: 'clicked',
								next_state: muted ? 'unmute' : 'mute',
								event_page: 'plan_landing_page',
							});
							setMuted(!muted);
						}}>
						<img src={muted ? volumeMutedIcon : volumeIcon} alt='' />
					</div>
				</div>
				<div className='plan-screen-instructors'>
					<p className='plan-screen-title'>
						Top <span>1% seniormost</span>
						<br />
						instructors for you
					</p>
					<Swiper
						spaceBetween={10}
						slidesPerView={1.5}
						centeredSlides
						loop={true}
						speed={3000}
						freeMode={true}
						modules={[Autoplay]}
						autoplay={{
							delay: 0,
							disableOnInteraction: false,
						}}
						className='plan-screen-instructors-swiper'>
						<SwiperSlide className='plan-screen-instructors-swiper-slide'>
							<img src={planInstructor1} alt='' />
						</SwiperSlide>
						<SwiperSlide className='plan-screen-instructors-swiper-slide'>
							<img src={planInstructor2} alt='' />
						</SwiperSlide>
						<SwiperSlide className='plan-screen-instructors-swiper-slide'>
							<img src={planInstructor3} alt='' />
						</SwiperSlide>
						<SwiperSlide className='plan-screen-instructors-swiper-slide'>
							<img src={planInstructor4} alt='' />
						</SwiperSlide>
						<SwiperSlide className='plan-screen-instructors-swiper-slide'>
							<img src={planInstructor5} alt='' />
						</SwiperSlide>
						<SwiperSlide className='plan-screen-instructors-swiper-slide'>
							<img src={planInstructor6} alt='' />
						</SwiperSlide>
						<SwiperSlide className='plan-screen-instructors-swiper-slide'>
							<img src={planInstructor7} alt='' />
						</SwiperSlide>
						<SwiperSlide className='plan-screen-instructors-swiper-slide'>
							<img src={planInstructor8} alt='' />
						</SwiperSlide>
					</Swiper>
				</div>
				<div className='plan-screen-founders'>
					<div className='plan-screen-founders-bg'></div>
					<p className='plan-screen-title'>
						Meet our <span>founders</span>
					</p>
					<Swiper
						spaceBetween={10}
						slidesPerView={1.2}
						loop={true}
						speed={600}
						autoplay={{
							delay: 3000,
							disableOnInteraction: true,
						}}
						modules={[Autoplay]}
						className='plan-screen-founders-swiper'>
						{Array.from(Array(20).keys()).map((_, index) => {
							return (
								<>
									<SwiperSlide
										key={index}
										className='plan-screen-founders-swiper-slide'>
										<img src={planFounders1} alt='' />
									</SwiperSlide>
									<SwiperSlide
										key={index + 100}
										className='plan-screen-founders-swiper-slide'>
										<img src={planFounders2} alt='' />
									</SwiperSlide>
								</>
							);
						})}
					</Swiper>
				</div>
				<div className='plan-screen-users'>
					<img src={planHappyUsers} alt='' />
				</div>
				<div className='plan-screen-faqs'>
					<p className='plan-screen-title'>FAQs</p>
					{Faqs?.map((faq, index) => (
						<FAQ
							key={index}
							newPlan
							selectedIndex={selectedFAQIndex}
							index={index}
							item={faq}
							onClickHandler={onFAQClickHandler}
						/>
					))}
				</div>
				<div className='plan-screen-footer'>
					{plans &&
						plans.map((plan: any, index: number) => (
							<>
								<PlanVariant
									count={plans?.length}
									free_extension={plan?.free_extension}
									offer={plan?.offer}
									extra_offer={plan?.extra_offer}
									longerPlan={onetime ? 90 : 30}
									strike_price={plan?.strike_price}
									pause_limit={plan?.pause_limit}
									key={index}
									app_display_name={
										onetime ? plan.display_name : plan?.app_display_name
									}
									onClick={(selectedIndex) =>
										handlePlanVariantClick(
											selectedIndex,
											plan?.duration,
											plan?.total_price
										)
									}
									setShowPauseBanner={() => {}}
									index={index}
									name={plan?.display_name || plan?.name}
									selectedIndex={selectedPlanVariantIndex || 0}
									type={plan?.type}
									duration={plan?.duration}
									monthly_price={plan?.monthly_price}
									total_price={plan?.total_price}
									recommended={plan.recommended}
								/>
							</>
						))}
					<div style={{ marginTop: '8px' }}>
						<PrimaryButton
							onClick={() => handleProceedToPay(false)}
							shimmer
							disabled={isPayNowClicked}
							style={{
								width: '100%',
								borderRadius: '8px',
								padding: '12px 0',
								overflow: 'hidden',
							}}>
							<p>Proceed to pay</p>
						</PrimaryButton>
					</div>
					{!onetime && (
						<p className='plan-screen-footer-cancel'>Cancel at anytime</p>
					)}
				</div>
			</div>
			<PaymentFailedModal
				paymentFailedPopup={paymentFailedPopup}
				setPaymentFailedPopup={setPaymentFailedPopup}
				selectedPlanVariant={selectedPlanVariant}
				addonAdded={false}
				onRetryPayment={handleRetryPayment}
			/>
			<BottomSheet
				show={showLoginPopup}
				customStyle={{ height: 'max-content' }}
				onHide={() => {}}
				content={
					<div className='plan-screen-login-modal'>
						{signupLoading && <Loader />}
						<div
							onClick={() => setShowLoginPopup(false)}
							style={{
								width: '100%',
								alignItems: 'end',
								display: 'flex',
								justifyContent: 'end',
							}}>
							<img
								style={{ alignSelf: 'end' }}
								src={CloseRecordingModelIcon}
								alt=''
							/>
						</div>
						<div
							className='login-form-title'
							style={{ textAlign: 'center', width: '100%', margin: '8px 0' }}>
							Fill your details
						</div>
						<span className={'form-title margin-top-40px'}>Your name</span>
						<TextInput
							placeholder='Name'
							value={userName}
							onChange={handleChangeUserName}
						/>
						<div style={{ height: '4px' }}></div>
						<span className={'form-title'}>WhatsApp Number</span>
						<PhoneInput
							phoneNumber={phone}
							defaultCountry='in'
							onPhoneChange={handlePhoneChange}
						/>
						<PrimaryButton
							disabled={
								userName.length < 3 || phone.length < 12 || signupLoading
							}
							onClick={handleSignup}
							style={{
								width: '100%',
								borderRadius: '8px',
								padding: '12px 0 0',
								overflow: 'hidden',
								marginTop: '16px',
							}}>
							<p>Proceed to pay</p>
						</PrimaryButton>
					</div>
				}
			/>
		</>
	);
}

export default PlanScreen;
