import React from 'react';

type Props = {
	image: string;
	title: string;
	desc: string;
};

const PlanScreenDesignedItem: React.FC<Props> = ({ image, title, desc }) => {
	return (
		<div className='plan-screen-designed-item'>
			<img src={image} alt='' />
			<p className='plan-screen-designed-item-title'>{title}</p>
			<p className='plan-screen-designed-item-desc'>{desc}</p>
		</div>
	);
};

export default PlanScreenDesignedItem;
