import React, { CSSProperties } from 'react';
import { Card } from 'react-bootstrap';
import './FAQ.css';
import { ReactComponent as UpArrow } from '../../../assets/new-icons/_xmark.svg';
import { ReactComponent as DownArrow } from '../../../assets/new-icons/_plus.svg';

interface FaqProps {
	page?: string;
	item: any;
	index: number;
	selectedIndex?: number | null;
	onClickHandler: (index: number) => void;
	style?: CSSProperties;
	dark?: boolean;
	addon?: boolean;
	newPlan?: boolean;
}

const FAQ: React.FC<FaqProps> = React.memo(
	({
		item,
		index,
		selectedIndex,
		onClickHandler,
		page,
		style,
		dark,
		addon,
		newPlan,
	}) => {
		return (
			<div
				style={
					index === selectedIndex
						? {
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								maxWidth: addon ? '402px' : newPlan ? '420px' : '375px',
								borderRadius: '12px',
								paddingTop: '12px',
								paddingLeft: '16px',
								border: dark ? '1px solid #4a4a4a' : '1px solid #FAA53D',
								background: dark ? '#232323' : '#FFF6EC',
								justifyContent: 'center',
								alignItems: 'start',
								marginTop: '16px',
								position: 'relative',
						  }
						: {
								borderRadius: '12px',
								display: 'flex',
								background: dark ? '#232323' : '#FFF',
								paddingLeft: '16px',
								border: dark ? '1px solid #4a4a4a' : '1px solid #D6D6D6',
								flexDirection: 'column',
								width: '100%',
								maxWidth: addon ? '402px' : newPlan ? '420px' : '375px',
								justifyContent: 'center',
								alignItems: 'start',
								marginTop: '16px',
								position: 'relative',
						  }
				}>
				<div
					onClick={onClickHandler.bind(null, index)}
					className={
						index === selectedIndex ? 'faq-container-selected' : 'faq-container'
					}>
					<p
						className={'question'}
						style={{
							...style,
							marginTop: index === selectedIndex ? '0px' : '15px',
							fontWeight: index === selectedIndex ? '500' : '400',
							color: dark ? '#ffffff' : '#4A4A4A',
							fontFamily:
								index === selectedIndex ? 'Gordita-Medium' : 'Gordita-Regular',
						}}>
						{item.module ? item.module : item.q}
					</p>
					{index === selectedIndex && (
						<div style={{ flexDirection: 'column', display: 'flex' }}>
							{item?.name && (
								<p
									className={'answer'}
									style={{ color: dark ? '#ffffff' : '#1a1a1a', ...style }}>
									{item.name}
								</p>
							)}
							{item?.a && (
								<p className={'answer'} style={style}>
									{item.a}
								</p>
							)}
							{item?.b && (
								<p className={'answer'} style={style}>
									{item?.b}
								</p>
							)}
							{item?.c && (
								<p className={'answer'} style={style}>
									{item?.c}
								</p>
							)}
							{item?.d && (
								<p className={'answer'} style={style}>
									{item?.d}
								</p>
							)}
						</div>
					)}
				</div>
				{index === selectedIndex ? (
					<UpArrow
						onClick={onClickHandler.bind(null, index)}
						style={{ position: 'absolute', top: 12, right: 12 }}
						height={20}
						width={20}
					/>
				) : dark ? (
					<div
						style={{
							position: 'absolute',
							top: '50%',
							transform: 'translateY(-50%)',
							right: 12,
							color: '#fff',
							fontSize: '24px',
							pointerEvents: 'none',
						}}>
						+
					</div>
				) : (
					<DownArrow
						onClick={onClickHandler.bind(null, index)}
						style={{
							position: 'absolute',
							top: '50%',
							right: 10,
							transform: 'translateY(-50%)',
						}}
						height={32}
						width={32}
					/>
				)}
			</div>
		);
	}
);

export default FAQ;
