import React, { CSSProperties, ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import './CustomCard.css';

interface CustomCardProps {
	onClick?: any;
	children: ReactNode;
	style?: CSSProperties;
	bodyStyle?: CSSProperties;
}

const CustomCard: React.FC<CustomCardProps> = ({
	children,
	style,
	onClick,
	bodyStyle,
}) => {
	return (
		<Card onClick={onClick} className={'custom-card'} style={style}>
			<Card.Body style={bodyStyle}>{children}</Card.Body>
		</Card>
	);
};

export default CustomCard;
