import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthenticatedUsers from '../../../hooks/useAuthenticatedUsers';
import PaymentSuccess from '../../../assets/animations/payment-suceess.gif';
import { Mixpanel } from '../../../analytics/Mixpanel';
import PrimaryButton from '../../../components-v2/buttons/PrimaryButton';

const CoursePaymentSuccessScreen = () => {
	const userData = useAuthenticatedUsers(true);
	const navigate = useNavigate();
	const location = useLocation();
	const course = location.state && location.state?.course;

	const onJoinWhatsAppsCommunityHandler = async () => {
		try {
			let whatsappGroupLink =
				'https://chat.whatsapp.com/DjjW2D53bOsK8aZ7YpAFZI';
			Mixpanel.track('click_join_whatsapp_course_group', {
				event_action: 'clicked',
				context_user_id: userData?.uid,
				course_name: course?.title,
				phone: userData?.phoneNumber || userData?.phone,
			});
			window.open(course?.whatsapp_group_link || whatsappGroupLink, '_blank');
			setTimeout(() => {
				navigate(`/upcoming-course/${course?.courseId}`);
			}, 1000);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		Mixpanel.track('view_course_payment_successful_screen', {
			event_action: 'viewed',
			course_name: course?.title,
			phone: userData?.phoneNumber || userData?.phone,
		});

		return () => {
			document.body.style.overflow = 'initial';
		};
	}, [userData]);

	useEffect(() => {
		const handlePopState = (event: any) => {
			window.history.pushState(null, document.title, window.location.href);
		};

		window.history.pushState(null, document.title, window.location.href);
		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, []);

	return (
		<div className='payment-success-container'>
			<img
				src={PaymentSuccess}
				style={{ alignSelf: 'center', width: 84, height: 84 }}
				alt='Landing Banner'
			/>
			<span className='payment-success-hurray'>Payment Successful 🎉</span>

			<span
				style={{ padding: '0 16px' }}
				className='payment-success-plan-description'>
				You have successfully enrolled in <span>{course?.title}</span>
			</span>

			<div>
				<PrimaryButton
					style={{ width: '300px', marginTop: '24px', borderRadius: '8px' }}
					onClick={onJoinWhatsAppsCommunityHandler}>
					<span>Join whatsapp group</span>
				</PrimaryButton>
			</div>
		</div>
	);
};

export default CoursePaymentSuccessScreen;
