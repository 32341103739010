import React, { useEffect, useState } from 'react';
import PaymentSuccess from '../../assets/animations/payment-suceess.gif';
import './PaymentSuccessScreen.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Mixpanel } from '../../analytics/Mixpanel';
import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
import AppDownloadNudge from '../../components-v2/app-download-nudge/AppDownloadNudge';
import { isDesktop } from 'react-device-detect';

export enum STATES {
	PAYMENT_SUCCESSFUL_ONE,
}

interface PaymentSuccessModalProps {}

const PlanPaymentSuccess: React.FC<PaymentSuccessModalProps> = ({}) => {
	const [state, setState] = useState(STATES.PAYMENT_SUCCESSFUL_ONE);
	const userData = useAuthenticatedUsers(true);
	const navigate = useNavigate();
	const location = useLocation();
	const selectedPlanVariant =
		location.state && location.state?.selectedPlanVariant;
	const course = location.state && location.state?.course;
	useEffect(() => {
		document.body.style.overflow = 'hidden';
		Mixpanel.track('view_payment_successful_screen', {
			event_action: 'viewed',
			plan_duration: selectedPlanVariant?.duration,
			phone: userData?.phoneNumber || userData?.phone,
		});

		setTimeout(() => {
			navigate('/home');
		}, 60000);

		return () => {
			document.body.style.overflow = 'initial';
		};
	}, [selectedPlanVariant, userData]);

	useEffect(() => {
		const handlePopState = (event: any) => {
			window.history.pushState(null, document.title, window.location.href);
		};

		window.history.pushState(null, document.title, window.location.href);
		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, []);

	useEffect(() => {
		if (isDesktop) {
			setTimeout(() => {
				navigate('/');
			}, 3000);
		}
	}, [navigate]);

	if (state === STATES.PAYMENT_SUCCESSFUL_ONE) {
		return (
			<div className='payment-success-container'>
				<img
					src={PaymentSuccess}
					style={{ alignSelf: 'center', width: 84, height: 84 }}
					alt='Landing Banner'
				/>
				<span className='payment-success-hurray'>Payment Successful 🎉</span>
				{course ? (
					<span
						style={{ padding: '0 16px' }}
						className='payment-success-plan-description'>
						You have successfully bought{' '}
						<span>{selectedPlanVariant?.name} plan</span> and{' '}
						<span>{course?.title}</span> course
					</span>
				) : (
					<span
						style={{ padding: '0 16px' }}
						className='payment-success-plan-description'>
						You have successfully bought{' '}
						<span>{selectedPlanVariant?.name} plan</span>
					</span>
				)}
				<AppDownloadNudge screenName={'Payment'} />
			</div>
		);
	}

	return null;
};

export default PlanPaymentSuccess;
