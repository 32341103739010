import React, { useEffect, useState } from 'react';

type Props = {
	title: string;
	list: string[];
	videos: string[];
};

const PlanScreenAboutItem: React.FC<Props> = ({ title, list, videos }) => {
	const [textIndex, setTextIndex] = useState<number>(0);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (textIndex === 2) {
				setTextIndex(0);
			} else {
				setTextIndex((prev) => prev + 1);
			}
		}, 5000);

		return () => {
			clearTimeout(timeout);
		};
	}, [textIndex]);

	return (
		<div className='plan-screen-about-item'>
			<div className='plan-screen-about-item-video'>
				<video
					style={{ opacity: textIndex === 0 ? '1' : '0' }}
					src={videos[0]}
					playsInline
					autoPlay
					muted
					loop
				/>
				<video
					style={{ opacity: textIndex === 1 ? '1' : '0' }}
					src={videos[1]}
					playsInline
					autoPlay
					muted
					loop
				/>
				<video
					style={{ opacity: textIndex === 2 ? '1' : '0' }}
					src={videos[2]}
					playsInline
					autoPlay
					muted
					loop
				/>
			</div>
			<p className='plan-screen-about-item-title'>{title}</p>
			<div className='plan-screen-about-item-list'>
				<div className='plan-screen-about-item-list-left'></div>

				<div className='plan-screen-about-item-list-item'>
					<div
						style={
							textIndex === 0
								? { height: '100%', transition: 'all 5s' }
								: { height: '0', transition: 'none' }
						}
						className='plan-screen-about-item-list-item-left'></div>
					<p>{list[0]}</p>
				</div>
				<div className='plan-screen-about-item-list-item'>
					<div
						style={
							textIndex === 1
								? { height: '100%', transition: 'all 5s' }
								: { height: '0', transition: 'all 0.5s' }
						}
						className='plan-screen-about-item-list-item-left'></div>
					<p>{list[1]}</p>
				</div>
				<div className='plan-screen-about-item-list-item'>
					<div
						style={
							textIndex === 2
								? { height: '100%', transition: 'all 5s' }
								: { height: '0', transition: 'none' }
						}
						className='plan-screen-about-item-list-item-left'></div>
					<p>{list[2]}</p>
				</div>
			</div>
		</div>
	);
};

export default PlanScreenAboutItem;
