import React, { memo, useEffect, useState } from 'react';
import './PlanVariant.css';
import { ReactComponent as RecommendedTag } from '../../../assets/recommended.svg';
import { COLORS } from '../../../utils/colors';
import CustomCard from '../../../components/card/CustomCard';
import { useSelector } from 'react-redux';
import planUsersChoice from './../../../assets/webp-images/plan-users-choice.png';

interface CircularCheckboxProps {
	title: string;
	subtitle: string;
	index: number;
	monthlyPrice: number | null;
	selectedIndex: number;
}

const CircularRadioButton: React.FC<CircularCheckboxProps> = ({
	title,
	subtitle,
	index,
	monthlyPrice,
	selectedIndex,
}) => {
	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		setIsChecked(index === selectedIndex);
	}, [index, selectedIndex]);

	return (
		<div className='price-container'>
			<div className='price-left-container'>
				<div
					className={`${
						isChecked ? 'price-radio-selected' : 'price-radio'
					}`}></div>
				<span className='price-title'>
					<span
						style={{
							color: '#000000',
							fontSize: '16px',
							display: 'block',
						}}>
						{title}
					</span>
					{!!monthlyPrice && (
						<span
							style={{
								color: '#000000',
								fontSize: '12px',
								display: 'block',
								marginTop: '-2px',
							}}>
							{`₹${subtitle}`}
						</span>
					)}
				</span>
			</div>
			<span
				className={`${
					isChecked ? 'price-subtitle-selected' : 'price-subtitle'
				}`}>
				{!!monthlyPrice ? `₹${monthlyPrice}/mon` : `₹${subtitle}/mon`}
			</span>
		</div>
	);
};

interface PlanVariantProps {
	strike_price: number;
	extra_offer: number;
	free_extension: string;
	offer: string;
	utm_campaign?: string;
	name: string;
	monthly_price: number;
	total_price: number;
	duration: number;
	recommended: boolean;
	type: string;
	index: number;
	longerPlan?: number;
	selectedIndex: number;
	onClick: (index: number) => void;
	pause_limit: number;
	app_display_name?: string;
	setShowPauseBanner: any;
	count: number;
}

const PlanVariant: React.FC<PlanVariantProps> = ({
	count,
	setShowPauseBanner,
	pause_limit,
	strike_price,
	extra_offer,
	free_extension,
	offer,
	longerPlan,
	utm_campaign,
	name,
	monthly_price,
	total_price,
	app_display_name,
	duration,
	recommended,
	type,
	index,
	selectedIndex,
	onClick,
}) => {
	const [longerPlanCheckDuration, setLongerPlanCheckDuration] = useState(
		longerPlan || 30
	);

	const user = useSelector((state: any) => state?.auth?.user?.data);

	return (
		<>
			{/* {duration > longerPlanCheckDuration && (
				<div style={{ width: '100%', maxWidth: '375px' }}>
					<img
						src={planUsersChoice}
						style={{
							width: '100px',
							marginTop: '16px',
							marginRight: 'auto',
						}}
						alt=''
					/>
				</div>
			)} */}
			<CustomCard
				onClick={onClick.bind(null, index)}
				bodyStyle={{ padding: '8px 12px' }}
				style={{
					height: 'auto',
					marginTop: '8px',
					width: '100%',
					maxWidth: '420px',
					borderRadius: '12px',
					backgroundColor: COLORS.WHITE,
					position: 'relative',
					border:
						index === selectedIndex
							? '1px solid var(--Primary-Gradient, #4462EF)'
							: '1px solid var(--Light-Grey-2, #E4E4E4)',
					// paddingBottom: duration > longerPlanCheckDuration ? '36px' : '0',
				}}>
				{offer && duration > longerPlanCheckDuration && (
					<div className='plan-variants-badge'>{offer}</div>
				)}

				<div className={`plan-variants-row`}>
					<CircularRadioButton
						index={index}
						monthlyPrice={monthly_price}
						selectedIndex={selectedIndex}
						title={app_display_name || ''}
						subtitle={String(total_price)}
					/>
					{recommended && (
						<RecommendedTag
							style={{ position: 'absolute', right: 10, top: -12 }}
							width={103}
							height={24}
						/>
					)}
				</div>
				{/* {duration > longerPlanCheckDuration && (
					<div className='plan-variants-monthly-price'>
						<p>
							🥳 Starting at ₹{Math.round(total_price / (duration / 30))}
							/month
						</p>
					</div>
				)} */}
			</CustomCard>
		</>
	);
};

export default memo(PlanVariant);
